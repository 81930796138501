

//function generateElements(html) {
//  const template = document.createElement('template');
//  template.innerHTML = html.trim();
//  return template.content.children;
//}

window.generateElements = function(html) {
  const template = document.createElement('template');
  template.innerHTML = html.trim();
  return template.content.children;
  
}

//export default generateElements;